/**
 * hide scrollbars
 */
body::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}
.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

#root {
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
}
#root::-webkit-scrollbar{
  display: initial;
  width: 100% !important;
}

.link {
  @apply text-blue-500;
}

.shadow-outline-red {
  box-shadow: 0 0 0 3px theme("colors.red.300");
}
.shadow-outline-green {
  box-shadow: 0 0 0 3px theme("colors.green.300");
}
.shadow-outline-white {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 1);
}
.shadow-outline-black {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 1);
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.react-icons {
  vertical-align: middle;
}
.enigoo-tooltip {
  background-color: black !important;
  opacity: 1 !important;
  color: white !important;
  border: 1px solid white !important;
  -webkit-box-shadow: 2px 2px 8px 0px rgb(0 0 0 / 18%);
  -moz-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.18);
  box-shadow: 2px 2px 8px 0px rgb(0 0 0 / 18%);
  margin-left: 4px !important;
  z-index: 9999 !important;
}

.enigoo-tooltip:after {
  border-right-style: none !important;
  border-right-width: 0 !important;
}

.enigoo-tooltip:before {
  border-top: none !important;
  border-bottom: none !important;
  left: -8px;
  top: 50%;
  margin-top: -5px;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.bg-1 {
  background: url(/bg-login-1.jpg) no-repeat 0 0 fixed;
  background-size: cover;
}
.bg-login-2 {
  background: url(/bg-login.png) no-repeat 0 0 fixed;
  background-size: cover;
}
.bg-login-3 {
  background: url(/bg-login.png) no-repeat 0 0 fixed;
  background-size: cover;
}

.w-96 {
  width: calc(theme("spacing.1") * 96);
}
.w-128 {
  width: calc(theme("spacing.1") * 128);
}

/* social media colors */
.text-facebook {
  color: #365397;
}
.text-twitter {
  color: #00a9f1;
}
.text-github {
  color: #2f2f2f;
}

.text-linkedin {
  color: #006db3;
}
.text-apple {
  color: #737373;
}
.text-google {
  color: #4285f4;
}
.text-google-plus {
  color: #e0452c;
}
.text-youtube {
  color: #ff3333;
}
.text-vimeo {
  color: #48b6ed;
}
.text-pinterest {
  color: #ce1a19;
}
.text-yelp {
  color: #c30f00;
}
.text-dribbble {
  color: #ed4584;
}
.text-amazon {
  color: #ff9700;
}
.text-skype {
  color: #00acf4;
}
.text-instagram {
  color: #396d9a;
}
.text-dropbox {
  color: #0d84de;
}
.text-flickr {
  color: #ea0066;
}
.text-tumblr {
  color: #304c68;
}
.text-foursquare {
  color: #207dc5;
}
